@import url('https://fonts.cdnfonts.com/css/inter');
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Redaction';
  src: url('fonts/Redaction-Regular.otf');
}

h3 {
  font-family: 'Redaction', serif;
  font-size: 3rem;
  font-weight: 400;
  line-height: 3rem;
  font-style: normal;
  color: #F8F8F9;
}

h5 {
  font-family: 'Redaction', serif;
}

article u {
  text-decoration-color: #17e0a8;
}

article h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2rem;
  letter-spacing: -0.05em;
  margin-top: 1.5rem;
}

article h3 {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  line-height: 1.75rem;
  letter-spacing: -0.05em;
  color: #0C0C0D;
}

article h4 {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: -0.05em;
}

article p {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: -0.015em;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

article ul {
  list-style: disc;
  margin-left: 1rem;
}

@media screen and (min-width: 640px) {
  article h2 {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 3rem;
    line-height: 3.25rem;
    letter-spacing: -0.05em;
    margin-top: 2.5rem;
  }

  article h3 {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    line-height: 2.5rem;
    letter-spacing: -0.05em;
    color: #0C0C0D;
  }

  article h4 {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: -0.05em;
  }

  article p {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.5rem;
    letter-spacing: -0.015em;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}

body {
  margin: 0;
  background-color: #F8F8F9;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes arrow-move {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  25% {
    transform: translate(8px, -8px);
    opacity: 0.5;
  }
  50% {
    transform: translate(16px, -16px);
    opacity: 0;
  }
  75% {
    transform: translate(-16px, 16px);
    opacity: 0.5;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.hover\:animate-container:hover .arrow-image {
  animation: arrow-move 0.3s ease-in-out 1;
}

.arrow-image {
  position: absolute;
  top: 0; /* Centering the arrow in the 16x16 box */
  left: 0;
}


.border-gradient {
  position: relative;
  border: 2px solid transparent; /* Ensure a base border exists */
  border-radius: 1000px;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background: linear-gradient(
          #0C0C0D,
          #0C0C0D
  ) padding-box, /* Background inside the border */
  linear-gradient(
          to right,
          #17e0a8,
          #00e1d3,
          #00dffb,
          #00d9ff,
          #60d0ff,
          #a6c3ff,
          #dab5ff,
          #ffa8fe
  ) border-box; /* Gradient for the border */
}

.text-gradient {
  background: linear-gradient(
          to right,
          #17e0a8,
          #00e1d3,
          #00dffb,
          #00d9ff,
          #60d0ff,
          #a6c3ff,
          #dab5ff,
          #ffa8fe
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.switch-container {
  transition: width 0.7s ease-in-out;
}

.mobile-slider button {
  touch-action: none;
  width: 60px;
}

.slider button {
  min-width: 60px;
}


